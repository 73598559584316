import React, { useLayoutEffect, useState } from 'react'
import Img from 'gatsby-image'

// Components
import ButtonMinimal from 'components/elements/ButtonMinimal'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'
import { motion } from 'framer-motion'
import { useInView } from 'react-hook-inview'

const StyledPartners = styled.div``

const PartnerImg = styled(Img)`
  position: absolute !important;
  height: 148px;
  width: 148px;
  top: -70px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
`

const Partner = styled(motion.div)`
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  padding: 100px 5px 120px 5px;
  position: relative;
  max-height: 375px;

  h2 {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      background: ${(props) => props.theme.color.gradient.main};
      width: 120%;
      height: 3px;
      left: -10%;
      border-radius: 1.5px;
      bottom: -20px;
    }
  }

  p {
    font-size: ${(props) => props.theme.font.size.s};
    margin-bottom: 0;
    line-height: 20px;

    @media screen and (max-width: 1199px) {
      padding: 0 30px 0 30px;
    }

    @media screen and (max-width: 991px) {
      padding: 0 30px 0 30px;
    }
  }

  @media screen and (max-width: 1199px) {
    max-height: 400px;
  }

  @media screen and (max-width: 991px) {
    max-height: 375px;
  }

  @media screen and (max-width: 768px) {
    max-height: 400px;
  }
`

const PartnerRow = styled.div`
  margin-left: -100px;
  margin-right: -100px;

  @media screen and (max-width: 1310px) {
    margin-left: -70px;
    margin-right: -70px;
  }

  @media screen and (max-width: 1199px) {
    margin-left: -50px;
    margin-right: -50px;
  }

  @media screen and (max-width: 991px) {
    margin-left: unset;
    margin-right: unset;
  }
`

const PartnerButton = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
`

const Partners = ({ className, content }) => {
  const [ref, isVisible] = useInView({
    threshold: 0.3,
  })

  const [view, setView] = useState(false)

  useLayoutEffect(() => {
    if (isVisible && !view) {
      setView(true)
    }
  }, [isVisible])

  return (
    <StyledPartners className={`container ${className ? `${className}` : ``}`}>
      <PartnerRow ref={ref} className="row justify-content-center">
        {content.map((partner, index) => (
          <div className="col-sm-6 col-lg-3 mb-lg-0 mb-5 pb-lg-5 pb-5 pt-lg-5 pt-3">
            <Partner
              whileHover={{
                y: -10,
                transition: { type: 'spring', delay: 0, duration: 0.2 },
              }}
              initial={{ opacity: 1, y: 80 }}
              animate={
                isVisible
                  ? {
                      opacity: 1,
                      y: 0,
                      transition: {
                        type: 'spring',
                        duration: 0.5,
                        delay: index * 0.2,
                      },
                    }
                  : {}
              }
            >
              <PartnerImg
                loading="eager"
                fadeIn={false}
                fluid={
                  partner.acf.preview.image.localFile.childImageSharp.fluid
                }
                alt=""
              />
              <div className="d-flex justify-content-center pb-4">
                <h2 className="float-left font-weight-xl font-size-ms text-center">
                  {partner.title}
                </h2>
              </div>
              {parse(partner.acf.preview.description)}
              <PartnerButton>
                <ButtonMinimal to={partner.path} icon="arrow">
                  <p>Meer informatie</p>
                </ButtonMinimal>
              </PartnerButton>
            </Partner>
          </div>
        ))}
      </PartnerRow>
    </StyledPartners>
  )
}

export default Partners
